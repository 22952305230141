import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <hr />
    <h1>404</h1>
    <h2>Looks like you must have taken a wrong turn at Albuquerque!</h2>
    <hr />
  </Layout>
);

export default NotFoundPage;
