import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import './styles.scss';

const Header = ({ siteTitle }) => (
  <header>
    <h1 className="header">
      <Link to="/" className="transition noUnderline">{siteTitle}</Link>
    </h1>
  </header>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: 'lovatt.dev',
};

export default Header;
