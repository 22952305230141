/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import Helmet from 'react-helmet';

import Header from './Header';
import './styles.scss';

const toggleGrid = () => {
  if (!process.env.GATSBY_DEV) return;

  // eslint-disable-next-line no-undef
  const body = document.querySelector('body');
  body.className = (body.className === 'baselineGrid') ? '' : 'baselineGrid';
};

const Layout = ({ children }) => (
  <React.Fragment>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Lovatt.dev</title>
      {/* <link rel="canonical" href="https://mysite.com/example" /> */}
      <link href="https://fonts.googleapis.com/css?family=Old+Standard+TT:400,700" rel="stylesheet" />
      <link href="https://fonts.googleapis.com/css?family=Playfair+Display+SC:900" rel="stylesheet" />
      {/* <meta name="viewport" content="width=device-width, initial-scale=1" /> */}
      <meta name="viewport" content="width=device-width" />
    </Helmet>

    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <>
          <Header siteTitle={data.site.siteMetadata.title} />
          <div className="content" onDoubleClick={toggleGrid}>
            <main>{children}</main>
            <footer>
              {`©${new Date().getFullYear()}, Built with `}
              <a href="https://www.gatsbyjs.org" className="transition link-inline">Gatsby</a>
            </footer>
          </div>
        </>
      )}
    />
  </React.Fragment>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
